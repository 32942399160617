#tUL {
    list-style-type: none;
  }
  
  #tUL {
    margin: 0;
    padding: 0;
  }
  
  .caret {
    cursor: pointer;
    -webkit-user-select: none; /* Safari 3.1+ */
    -moz-user-select: none; /* Firefox 2+ */
    -ms-user-select: none; /* IE 10+ */
    user-select: none;
  }
  
  .caret::before {
    content: "\25B6";
    color: black;
    display: inline-block;
    margin-right: 6px;
  }
  
  .caret-down::before {
    -ms-transform: rotate(90deg); /* IE 9 */
    -webkit-transform: rotate(90deg); /* Safari */
    transform: rotate(90deg);  
  }
  
  .nested {
    display: none;
  }
  
  .active {
    display: block;
  }

  .color-edit{
    color:#0ec2de;
  }
  .color-edit-content{
    color:#007bff;
  }
  .color-addnew{
    color:#dc3545;
  }