.dash_opt .icon > i,
.dash_opt .icon > i.fa,
.dash_opt .icon > i.fas{
    font-size:100px !important;
    top:20vh !important;
    text-align: center !important;
}
.widget-user-image{
    float: left;
    height: auto;
}